import { useState } from 'react';
import { styled } from 'styled-components';
import { scrollTop } from '../../utils/scrollTop.js';

const TeamPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 20px 20px 0 20px;
  max-width: calc(100% - 40px); /* Adjust the max-width to account for margins */
`;

const TeamTittle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 20px;
  margin-bottom: 30px;
  border: 1px solid black;
    p {
    margin: 6px;
  }
`;

const ParagraphsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
  margin-bottom: 60px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const Paragraph = styled.p`
  height: auto;
  width: 33%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CuratorsBody = styled(ParagraphsContainer)`
  gap: 10px;
  margin-bottom: 60px;

  @media (max-width: 768px) {
    gap: 30px;
  }
`;

const Curator = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 33%;
  position: relative; /* Add relative positioning */

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CuratorImage = styled.img`
  display: flex;
  width: 100%;
  height: auto;
  position: relative;
  z-index: 1; /* Ensure the image is above the name */
  /* hide the name on hover */
`;

const CuratorName = styled.p`
  position: absolute;
  top: 22%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -22%); /* Center both vertically and horizontally */
  padding: 5px; /* Add some padding for readability */
  z-index: 2; /* Ensure the name is above the image */
`;

const CuratorText = styled.p`
  width: 100%;
  text-align: center;
`;

const CuratorLink = styled.a`
  text-decoration: none;
  color: #000;
  font-size: 9px;
  &:hover {
    text-decoration: underline;
  }
`;

const StudiosContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 60px;
    p {
      margin: 0;
    }
  position: relative;
`;

const Lorena = styled.p`
  position: absolute;
  bottom: -70px;
  right: 10px;
  margin: 0;
  font-size: 9px;

  a {
    text-decoration: none;
    color: #000;
  }


  @media (max-width: 768px) {
    position: relative;
    font-size: 10px;
  }
`;

const GoTop = styled.div`
  width: 100px;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
  gap: 5px;

  p {
    margin: 0;
  }

  img {
    width: 20px;
  }
`;

const TeamPage = () => {
  const [isHovered, setIsHovered] = useState(null);
  const isMobile = window.innerWidth < 768;

  const handleHover = (curator) => {
    setIsHovered(curator);
  };

  const handleUnhover = () => {
    setIsHovered(null);
  };

  return (
    <TeamPageContainer>
      <TeamTittle><p>ORGANIZERS</p></TeamTittle>
      <ParagraphsContainer>
        {!isMobile ? <>
        <Paragraph>
          AJAC, OFFICIALLY KNOWN AS THE AGRUPACIÓ DE JOVES
          ARQUITECTES DE CATALUNYA, WAS FOUNDED IN 1993 WITH A
          MISSION TO CHAMPION AND SPOTLIGHT THE VIBRANT COMMUNITY
          OF YOUNG ARCHITECTS IN CATALONIA. THE ORGANIZATION
          IS DEEPLY COMMITTED TO FACILITATING THE EARLY CAREER
          STAGES OF EMERGING ARCHITECTS, ADVOCATING FOR THEIR
          INTERESTS AND RIGHTS. THEY ACTIVELY COLLABORATE IN
          RESEARCH, PROVIDE SUPPORT, AND DISSEMINATE PROFESSIONAL
        </Paragraph>

        <Paragraph>
          OPPORTUNITIES, WHILE ALSO NURTURING THE CULTURAL
          DIMENSION OF ARCHITECTURE. OVER TIME, THANKS TO THEIR
          PRESTIGIOUS AWARDS, AJAC HAS EVOLVED INTO A LAUNCHPAD
          FOR EMERGING TALENTS, ELEVATING THEM TO INFLUENTIAL
          FIGURES WITHIN THE ARCHITECTURE AND DESIGN COMMUNITY.
          THE UPCOMING BIENNAL REPRESENTS A SIGNIFICANT STEP
          FORWARD. ENVISIONED AS A CULTURAL FESTIVAL, IT WILL
        </Paragraph>
        <Paragraph>
          FEATURE CONFERENCES, DEBATES, WORKSHOPS, INSTALLATIONS,
          THE EXHIBITION OF THE AWARDED PROJECTS, AND A
          PUBLICATION RELEASE. WITH THIS EVENT, AJAC AIMS TO
          FIRMLY ESTABLISH YOUNG ARCHITECTURE AS A PROMINENT
          CULTURAL REFERENCE POINT, ON PAR WITH RENOWNED
          EXHIBITIONS WORLDWIDE.
        </Paragraph>
        </> : <>
                <Paragraph>
                     AJAC, OFFICIALLY KNOWN AS THE AGRUPACIÓ DE JOVES
          ARQUITECTES DE CATALUNYA, WAS FOUNDED IN 1993 WITH A
          MISSION TO CHAMPION AND SPOTLIGHT THE VIBRANT COMMUNITY
          OF YOUNG ARCHITECTS IN CATALONIA. THE ORGANIZATION
          IS DEEPLY COMMITTED TO FACILITATING THE EARLY CAREER
          STAGES OF EMERGING ARCHITECTS, ADVOCATING FOR THEIR
          INTERESTS AND RIGHTS. THEY ACTIVELY COLLABORATE IN
          RESEARCH, PROVIDE SUPPORT, AND DISSEMINATE PROFESSIONAL           
          OPPORTUNITIES, WHILE ALSO NURTURING THE CULTURAL
          DIMENSION OF ARCHITECTURE. OVER TIME, THANKS TO THEIR
          PRESTIGIOUS AWARDS, AJAC HAS EVOLVED INTO A LAUNCHPAD
          FOR EMERGING TALENTS, ELEVATING THEM TO INFLUENTIAL
          FIGURES WITHIN THE ARCHITECTURE AND DESIGN COMMUNITY.
                </Paragraph>
                        <Paragraph>
          THE UPCOMING BIENNAL REPRESENTS A SIGNIFICANT STEP
          FORWARD. ENVISIONED AS A CULTURAL FESTIVAL, IT WILL FEATURE CONFERENCES, DEBATES, WORKSHOPS, INSTALLATIONS,
          THE EXHIBITION OF THE AWARDED PROJECTS, AND A
          PUBLICATION RELEASE. WITH THIS EVENT, AJAC AIMS TO
          FIRMLY ESTABLISH YOUNG ARCHITECTURE AS A PROMINENT
          CULTURAL REFERENCE POINT, ON PAR WITH RENOWNED
          EXHIBITIONS WORLDWIDE.
        </Paragraph>
        </> }
      </ParagraphsContainer>
      <TeamTittle><p>CURATORS</p></TeamTittle>
      <CuratorsBody>
         <Curator>
          <CuratorImage
            src={isHovered === 1 ? '/images/curators/curator_1_hover.webp' : '/images/curators/curator_1.png'}
            onMouseOver={() => handleHover(1)}
            onMouseLeave={handleUnhover}
          />
          <CuratorName style={{ opacity: isHovered === 1 ? 0 : 1 }}>EDUARD FERNÀNDEZ</CuratorName>
          <CuratorText>
            EDUARD FERNÀNDEZ IS AN ARCHITECT AND
            EDUCATOR. HE STUDIED AT ETSAB-UPC AND
            FAUP IN PORTO. HE HAS WORKED IN VARIOUS
            ARCHITECTURAL FIRMS IN BARCELONA AND
            SYDNEY, AND HAS COLLABORATED WITH CULTURAL
            INSTITUTIONS SUCH AS FAD, THE MIES VAN
            DER ROHE FOUNDATION, AND THE BARCELONA
            CITY COUNCIL. HE HAS ALSO BEEN TEACHING
            AT AUSTRALIAN UNIVERSITIES MONASH AND
            UTS. CURRENTLY, HE TEACHES THE BAU AND
            IED BARCELONA. ADDITIONALLY, HE IS THE
            CO-FOUNDER OF SELF-OFFICE, FOUNDED IN
            2022 AND BASED IN BARCELONA. THE OFFICE
            OPERATES IN THE FIELDS OF ARCHITECTURE
            AND RESEARCH AND HAS COLLABORATED WITH
            VARIOUS EUROPEAN INSTITUTIONS, INCLUDING
            THE DESIGN ACADEMY EINDHOVEN, THE HAUS DER
            ARCHITEKTUR GRAZ, AND THE VI PER GALLERY
            IN PRAGUE.
          </CuratorText>
          <CuratorLink href='https://www.self-office.net' target='_blank' rel='noreferrer'>SELF-OFFICE.NET</CuratorLink>
        </Curator>
        <Curator>
          <CuratorImage
            src={isHovered === 2 ? '/images/curators/curator_2_hover.webp' : '/images/curators/curator_2.png'}
            onMouseOver={() => handleHover(2)}
            onMouseLeave={handleUnhover}
          />
          <CuratorName style={{ opacity: isHovered === 2 ? 0 : 1 }}>LAURA SOLSONA</CuratorName>
          <CuratorText>
            LAURA SOLSONA IS AN ARCHITECT AND URBAN
            DESIGNER. SHE STUDIED AT ETSAB-UPC AND
            THE TU- BERLIN. SHE HAS COLLABORATED IN
            URBAN DESIGN AND LANDSCAPE STUDIOS IN
            BARCELONA AND SYDNEY, SUCH AS JORNET
            LLOP PASTOR ARQUITECTES IN BARCELONA AND
            ASPECT STUDIOS IN SYDNEY. SHE HAS ALSO
            TAUGHT ARCHITECTURE AND URBANISM COURSES
            AT MONASH UNIVERSITY IN MELBOURNE AND
            UTS IN SYDNEY. ADDITIONALLY, SHE IS THE
            CO-FOUNDER OF SELF-OFFICE, FOUNDED IN
            2022 AND BASED IN BARCELONA. THE OFFICE
            OPERATES IN THE FIELDS OF ARCHITECTURE
            AND RESEARCH AND HAS COLLABORATED WITH
            VARIOUS EUROPEAN INSTITUTIONS, INCLUDING
            THE DESIGN ACADEMY EINDHOVEN, THE HAUS DER
            ARCHITEKTUR GRAZ, AND THE VI PER GALLERY
            IN PRAGUE.
          </CuratorText>
          <CuratorLink href='https://www.self-office.net' target='_blank' rel='noreferrer'>SELF-OFFICE.NET</CuratorLink>
        </Curator>

        <Curator>
          <CuratorImage
            src={isHovered === 3 ? '/images/curators/curator_3_hover.webp' : '/images/curators/curator_3.png'}
            onMouseOver={() => handleHover(3)}
            onMouseLeave={handleUnhover}
          />
          <CuratorName style={{ opacity: isHovered === 3 ? 0 : 1 }}>ARNAU PASCUAL</CuratorName>
          <CuratorText>
            ARNAU PASCUAL IS AN ARCHITECT, EDITOR,
            AND RESEARCHER. HE STUDIED AT ETSAB-
            UPC AND ETH ZURICH. CURRENTLY, HE IS
            PURSUING AN MBARCH WITH A SPECIALIZATION
            IN THEORY, HISTORY, AND CULTURE AT ETSAB-
            UPC. HE IS ALSO TEACHING THEORY AT THE
            SAME UNIVERSITY AND AT ETSALS. HE HAS
            COLLABORATED WITH FIRMS SUCH AS POOL
            ARCHITEKTEN IN ZURICH, FLORES & PRATS, AND
            MATEOARQUITECTURA IN BARCELONA. HE SERVED
            AS THE EDITOR OF THE DIGITAL ARCHITECTURE
            PLATFORM TRANSFER GLOBAL ARCHITECTURE
            PLATFORM, BEING RESPONSIBLE FOR AND
            COORDINATING THE TAVA COMPETITION IN ITS
            2019 AND 2021 EDITIONS, HELD IN ZURICH
            AND LAUSANNE. HE IS THE EDITOR OF THE
            BOOK FOOTPRINTS WRITINGS 2005-2020 JOSEP
            LLUÍS MATEO - PARK BOOKS, 2021, AND HAS
            PUBLISHED VARIOUS ARTICLES IN MAGAZINES
            AND PLATFORMS SUCH AS URBANNEXT, PASARELA,
            AND QUADERNS.
          </CuratorText>
          <CuratorLink href='https://www.arnau-pascual.com' target='_blank' rel='noreferrer'>ARNAU-PASCUAL.COM</CuratorLink>
        </Curator>
        <Curator>
          <CuratorImage
            src={isHovered === 4? '/images/curators/curator_4_hover.webp' : '/images/curators/curator_4.png'}
            onMouseOver={() => handleHover(4)}
            onMouseLeave={handleUnhover}
          />
          <CuratorName style={{ opacity: isHovered === 4 ? 0 : 1 }}>MARINA POVEDANO</CuratorName>
          <CuratorText>
            MARINA POVEDANO GRADUATED IN PHILOSOPHY
            FROM UB AND IN ARCHITECTURE FROM ETSAB-
            UPC, SPECIALIZING IN CRITICAL, CURATORIAL,
            AND CONCEPTUAL PRACTICES AT COLUMBIA
            UNIVERSITY IN NEW YORK. CURRENTLY, SHE
            TEACHES ARCHITECTURAL THEORY AT ETSAB-
            UPC AND IS A MEMBER OF THE RESEARCH GROUP
            ARCHITECTURE, CITY, AND CULTURE, WITH
            WHOM SHE HAS PUBLISHED “FRAGILE SPACES IN
            THE CONTEMPORARY CITY.” SHE HAS CURATED
            EXHIBITIONS AT THE GUGGENHEIM MUSEUM
            NEW YORK, THE VENICE BIENNALE, AND MIAS
            ARCHITECTS, WITH HER LATEST EXHIBITION AT
            THE CENTRE POMPIDOU IN PARIS AND DHUB.
            SHE HAS PUBLISHED VARIOUS ARTICLES IN
            CINEMA, ARCHITECTURE, AND PHILOSOPHY
            JOURNALS AND BOOKS IN THE UNITED STATES,
            MEXICO, AND SPAIN.
          </CuratorText>
          <CuratorLink href='mailto:marinapore@gmail.com' target='_blank' rel='noreferrer'>MARINAPORE@GMAIL.COM</CuratorLink>
        </Curator>
      </CuratorsBody>
      <TeamTittle><p>AWARDED STUDIOS</p></TeamTittle>
    <StudiosContainer>
      <p>AMOO</p>
      <p>ANDREA + JOAN ARQUITECTES</p>
      {isMobile ? <><p>ANDRÉ DEL RÍO ARES + JORDI OLIVELLA </p> <p> CIRICI</p> <p>ARJUB STUDIO</p> </> : 
      <><p>ANDRÉ DEL RÍO ARES + JORDI OLIVELLA CIRICI</p>
      <p>ARJUB STUDIO</p> </>}
      <p>ARQUITECTURA DE CONTACTE</p>
      <p>ATIENZA MAURE ARQUITECTOS</p>
      <p>BAJET GIRAMÉ</p>
      <p>ESTUDI BIGA</p>
      <p>GERARD ROMEU COSCOLLA</p>
      { isMobile ? <><p>GLORIA CRISTINA MUÑOZ ALCALÁ + NICOLÁS</p> <p> MARTÍNEZ RUEDA</p> </> :
      <p>GLORIA CRISTINA MUÑOZ ALCALÁ + NICOLÁS MARTÍNEZ RUEDA</p> }
      <p>HIHA STUDIO</p>
      <p>JAVIER GAVÍN BALDA</p>
      <p>LÄNK ARQUITECTES</p>
      <p>MARIA AUCEJO MOLLÀ</p>
      <p>OJO ESTUDIO</p>
      <p>RUBEN CASQUERO</p>
      <p>TALLER LOCI</p>
      <p>TALLER11</p>

      <Lorena>GRAPHIC DESIGN / ART DIRECTION  <a target='_blank' href="https://www.instagram.com/lorengarciort/" rel='noreferrer'>@LORENGARCIORT</a></Lorena>
   {isMobile && <GoTop onClick={scrollTop} alt="go-top"><img src='/images/gotop.png' alt='top'></img> <p>GO TO THE</p> <p>TOP</p> </GoTop> }
    </StudiosContainer>
    </TeamPageContainer>
  );
}
export default TeamPage;
