import { styled } from 'styled-components';
import HomeGallery from '../../components/HomeGallery/HomeGallery.js';

const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const HomePage = () => {
  return (
    <HomePageContainer>
      <HomeGallery></HomeGallery>
    </HomePageContainer>
  );
}
export default HomePage;