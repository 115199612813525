import { styled } from 'styled-components';
import { scrollTop } from '../../utils/scrollTop.js';

const ManifestoPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 20px;
  max-width: calc(100% - 40px); /* Adjust the max-width to account for margins */
`;

const ManifestoTittle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 20px;
  margin-bottom: 60px;
  border: 1px solid black;
    p {
    margin: 6px;
  }

  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;

const ManifestoBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;

    p {
      margin: 0;
    }

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  position: relative;
  flex: 1 0 0; /* Added flex property */
`;

const Paragraph = styled.p`
  width: ${props => props.width};
  height: auto;
  align-self: ${props => props.$flexPosition};
  text-align: ${props => props.$textAlign};
  position: relative;
  top: ${props => props.$top};
  left: ${props => props.$left};
  margin-top: ${props => props.$marginTop} !important;
`;

const GoTop = styled.div`
  width: 100px;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 60px;
  gap: 5px;

  p {
    margin: 0;
  }

  img {
    width: 20px;
  }
`;

const ManifestoPage = () => {
  const isMobile = window.innerWidth < 768;

  return (
  <ManifestoPageContainer>
    <ManifestoTittle>
      <p>FOLLOW, POST, LIKE, SHARE, FOLLOW BACK</p>
    </ManifestoTittle>
    <ManifestoBody>
      <Column>
        <Paragraph width={isMobile ? '81%' : '60%'} $flexPosition={'flex-end'}>
          ARCHITECTURE IS PRODUCED, 
          DISSEMINATED, AND CONSUMED 
          THROUGH IMAGES. THE WIDESPREAD 
          USE OF IMAGES AS A MEANS OF COMMUNICATION,
          TOGETHER WITH THE EXTENSIVE USE OF
          NEW TECHNOLOGIES,
          HAS CREATED A SPACE
          FOR SHARED ENCOUNTERS AND DIALOGUE AMONG DISCIPLINES.
        </Paragraph>
        <Paragraph width={isMobile ? null : '95%'} $flexPosition={isMobile ? null : 'flex-start' }>
          THROUGH THE USE OF A COMMON
          LANGUAGE, THESE DISCIPLINES
          GENERATE A VISUAL CULTURE THAT
          IS INFLUENCED BY ALGORITHMIC
          RELATIONSHIPS OF SIMILARITY
          AND AFFINITY. WE CONSUME IT
          CONSTANTLY, ALMOST UNCONSCIOUSLY,
          IN OUR DAILY LIVES.
        </Paragraph>
      </Column>
      <Column>
        <Paragraph width={isMobile ? '58%' : '48.6%'} $flexPosition={'flex-start'} $left={isMobile ? '0' : '5%'} $marginTop={isMobile ? '15px' : null}>
          SOCIAL MEDIA
          HAVE BECOME AN
          ESSENTIAL PART
          OF THE ANALOG
          REALITY AS AN
          INDISPENSABLE
          CONDITION OF THE
          CREATIVE PROCESS:
        </Paragraph>
        <Paragraph width={isMobile ? '65%' : '70%'} $flexPosition={'center'} $textAlign={'right'} $left={isMobile ? '2%' : '-10%'}>
          A TOOL FOR COMMUNICATION
          AND DISSEMINATION THAT
          AIMS TO SATISFY THE
          DESIRE FOR LIKES. THIS
          DESIRE IS LINKED TO THE
          CONSTRUCTION OF DIGITAL
          IDENTITIES AND THEREFORE
          SEEKS RECOGNITION AND
          ADMIRATION FROM OTHERS
          IN ORDER TO GRANT
          AUTHENTICITY.
        </Paragraph>
        {isMobile ?  <Paragraph width={'100%'} $flexPosition={'center'} >
          BUT AT THE SAME TIME, THIS
          DESIRE TENDS TO EXCLUDE
          ANYTHING THAT IS DIFFERENT AND UNCOMFORTABLE, LIMITING
          CRITICISM AND ALTERNATIVE
          IDEOLOGICAL POSITIONS. HOW DO WE
          MAKE THE EYE OF THE 21ST CENTURY,
          ADDICTED TO THE TRANSIENCE AND
          OBSCENITY OF IMAGES, UNDERSTAND
          THEM AS A VEHICLE FOR UNVEILING
          INTIMATE STORIES THAT REQUIRE
          TWO LOST QUALITIES: REFLECTION
          AND CRITICISM?
        </Paragraph> : 
        <Paragraph width={'90%'} $flexPosition={'center'} >
          BUT AT THE SAME TIME, THIS
          DESIRE TENDS TO EXCLUDE
          ANYTHING THAT IS DIFFERENT
        </Paragraph>
        }
      </Column>
      <Column>
      {!isMobile && 
        <Paragraph>
          AND UNCOMFORTABLE, LIMITING
          CRITICISM AND ALTERNATIVE
          IDEOLOGICAL POSITIONS. HOW DO WE
          MAKE THE EYE OF THE 21ST CENTURY,
          ADDICTED TO THE TRANSIENCE AND
          OBSCENITY OF IMAGES, UNDERSTAND
          THEM AS A VEHICLE FOR UNVEILING
          INTIMATE STORIES THAT REQUIRE
          TWO LOST QUALITIES: REFLECTION
          AND CRITICISM?
        </Paragraph>
        }
        {isMobile ? <Paragraph width={'90%'} $flexPosition={'center'} $left={'5%'} $marginTop={isMobile ? '10px' : null} >
                THE CONSTRUCTION OF A
          MESSAGE, OF WHAT COMES
          NEXT, OF THE POST, AND
          AT THE SAME TIME, THE
          REJECTION OF BEING
          MERELY CONSUMABLE, IS
          WHAT VALIDATES THE IMAGE
          ITSELF AND PROVIDES IT
          WITH MEANING. THIS FACT
          IS PARTICULARLY RELEVANT
          DUE TO THE CONSTRUCTIVE NATURE OF ARCHITECTURE.
          CONFRONTING THE
          CURRENT ENVIRONMENTAL
          ISSUES GENERATES A NEW
          ANTHROPOCENIC AWARENESS
          THAT QUESTIONS THE
          HEGEMONIC ARCHITECTURAL
          DISCOURSE, EMPHASIZING
          THE MATERIAL PROCESSES
          AND THE INHERENT
          POLITICAL, ECONOMIC, AND
          CLIMATIC IMPLICATIONS OF
          EACH PROJECT.
        </Paragraph> :
        
        
        <Paragraph width={'70%'} $flexPosition={'flex-end'} $textAlign={'left'} $top={'5%'} $left={'-8%'}>
          THE CONSTRUCTION OF A
          MESSAGE, OF WHAT COMES
          NEXT, OF THE POST, AND
          AT THE SAME TIME, THE
          REJECTION OF BEING
          MERELY CONSUMABLE, IS
          WHAT VALIDATES THE IMAGE
          ITSELF AND PROVIDES IT
          WITH MEANING. THIS FACT
          IS PARTICULARLY RELEVANT
          DUE TO THE CONSTRUCTIVE
        </Paragraph>
      }
      </Column>
      <Column>
        {!isMobile && <Paragraph width={'78%'} $flexPosition={'flex-end'}>
          NATURE OF ARCHITECTURE.
          CONFRONTING THE
          CURRENT ENVIRONMENTAL
          ISSUES GENERATES A NEW
          ANTHROPOCENIC AWARENESS
          THAT QUESTIONS THE
          HEGEMONIC ARCHITECTURAL
          DISCOURSE, EMPHASIZING
          THE MATERIAL PROCESSES
          AND THE INHERENT
          POLITICAL, ECONOMIC, AND
          CLIMATIC IMPLICATIONS OF
          EACH PROJECT.
        </Paragraph> }
        { isMobile ? <Paragraph width={'64%'} $flexPosition={'flex-start'} $left={'0%'} $marginTop={isMobile ? '10px' : null} >
            THE 1ST
          BIENNIAL
          OF YOUNG
          ARCHITECTURE
          SEEKS TO
          HARNESS
          THE POWER
          OF IMAGES       TO REVEAL
          STORIES THAT
          DELVE INTO
          ARCHITECTURAL
          REFLECTION
          AND CRITICISM.
          </Paragraph> :
        <Paragraph width={'30%'} $flexPosition={'flex-start'} $top={'5%'}>
          THE 1ST
          BIENNIAL
          OF YOUNG
          ARCHITECTURE
          SEEKS TO
          HARNESS
          THE POWER
          OF IMAGES
        </Paragraph>
    }
      </Column>
      <Column>
      {!isMobile && <Paragraph width={'48%'} $flexPosition={'flex-start'}  >
          TO REVEAL
          STORIES THAT
          DELVE INTO
          ARCHITECTURAL
          REFLECTION
          AND CRITICISM.
        </Paragraph>
    }
        <Paragraph width={'70%'} $flexPosition={'flex-end'} $left={isMobile ? '-15%' : null} $marginTop={isMobile ? '10px' : null}>
          BOTH THE CONTENT
          OF THE EXHIBITION
          AND THE ACTIVITIES
          GENERATED AROUND THE
          BIENNIAL ADOPT THIS
          CHARACTERISTIC ANALOG-
          DIGITAL DYNAMIC OF
          CONTEMPORARY CULTURE,
          CHALLENGING THE
          PARADIGMS OF SOCIAL
          NETWORKS AND ADDRESSING
          THE CONCERNS THAT
          DIRECTLY AFFECT OUR
          GLOBAL SOCIETY THROUGH
          ARCHITECTURE.
        </Paragraph>
      </Column>
    </ManifestoBody>
   {isMobile && <GoTop onClick={scrollTop} alt="go-top"><img src='/images/gotop.png' alt='top'></img> <p>GO TO THE</p> <p>TOP</p> </GoTop> }
  </ManifestoPageContainer>
  )
}

export default ManifestoPage