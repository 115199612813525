import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { scrollTop } from '../../utils/scrollTop.js';

const NavBarContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 200px;
  align-items: center;
  position: relative;
  font-family: "ESK";

  @media (max-width: 768px) {
    height: 80px;
    background-color: #fff;
    z-index: 100;
    position: sticky;
    top: 0;
  }
`;

const NavBarButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 768px) {
    gap: 5px;
  }
`;

const NavBarButton = styled.button`
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  color: #000;
  font-family: "ESK";
  font-size: 12px;
  &:hover {
    text-decoration: underline;
  }
`;

const NavBarButtonLink = styled.a`
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  color: #000;
  font-family: "ESK";
  font-size: 12px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const HomeButton = styled(NavBarButton)`
  position: absolute;
  left: 20px;
  text-decoration: underline;
`;

const NavBar = () => {
 const navigate = useNavigate();

  function handleClick(route) {
    if(route.includes('http')) {

    }
    navigate(route);
    scrollTop();
  }

  const [showHomeButton, setShowHomeButton] = useState(true);

  const isHome = window.location.pathname === '/home';

  useEffect(() => {
    if (isHome) {
      setShowHomeButton(false);
    } else {
      setShowHomeButton(true);
    }
  }, [isHome]);

  return (
    <NavBarContainer>
      <NavBarButtonContainer>
        {showHomeButton && <HomeButton onClick={() => handleClick('/home')}>HOME</HomeButton> }
        <NavBarButton onClick={() => handleClick('/manifesto')}>MANIFESTO</NavBarButton>
        <NavBarButton onClick={() => handleClick('/team')}>TEAM</NavBarButton>
        <NavBarButtonLink target="a_blank" href='https://drive.google.com/drive/folders/1OnYdfh4_gVQaszjMpywJGpcoYaWpAPn1?usp=sharing' rel="noreferrer" >AGENDA &nbsp;(&nbsp;PDF&nbsp;)</NavBarButtonLink>
      </NavBarButtonContainer>
    </NavBarContainer>
  );
}

export default NavBar;