import { styled } from 'styled-components';

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
  height: 200px;
  max-width: 100vw;
`;

const FooterInstagram = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 20px;
  margin: 20px;
  border: 1px solid black;

    a {
      margin: 6px;
      color: #000;
      
    }
    &:hover {
      text-decoration: underline;

    }
`;

const FooterInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
    p {
      margin: 0;
    }
`;

const FooterLogos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  padding-bottom: 20px;
  gap: 40px;

  @media (max-width: 768px) {
    gap: 10px;
    flex-direction: column;
  }
`;

const Logo = styled.img`
  display: flex;
  height: 6.1vh;

  @media (max-width: 768px) {
    height: 4.9vh;
  }
`;

const LogosAndText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 9px;
  
    p{
    margin: 0;
  }
`;

const ThirdLogoGroup = styled.div`
  display: flex;
  flex-direction: row;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;


const Logos = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    flex-wrap: nowrap;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
    <FooterInstagram>
      <a target='_blank' href="https://www.instagram.com/biennalajac/" rel="noreferrer">@BIENNALAJAC</a>
    </FooterInstagram>
    <FooterInfo>
      <p> PLAÇA NOVA No5, 6a PLANTA,</p>
      <p>08002 BARCELONA</p>
      <p>(+34) 699 51 09 13</p>
      <p>BIENNALAJAC@COAC.NET</p>
    </FooterInfo>
    <FooterLogos>
      <LogosAndText>
        <p>ORGANIZED BY:</p>
      <Logos>
        <Logo src="/images/logos/logos-01.png"></Logo>
      </Logos>
      </LogosAndText>

      <LogosAndText>
        <p>WITH THE COLABORATION OF:</p>
      <Logos>
        <Logo src="/images/logos/logos-02.png"></Logo>
        <Logo src="/images/logos/logos-03.png"></Logo>
        <Logo src="/images/logos/logos-04.png"></Logo>
      </Logos>
      </LogosAndText>

      <LogosAndText>
        <p>SUPPORTED BY:</p>
        <ThirdLogoGroup>
      <Logos>
        <Logo src="/images/logos/logos-041.png"></Logo>
        <Logo src="/images/logos/logos-05.png"></Logo>
        <Logo src="/images/logos/logos-06.png"></Logo>
        <Logo src="/images/logos/logos-07.png"></Logo>
        <Logo src="/images/logos/logos-08.png"></Logo>
      </Logos>
      <Logos>
        <Logo src="/images/logos/logos-09.png"></Logo>
        <Logo src="/images/logos/logos-10.png"></Logo>
        <Logo src="/images/logos/logos-11.png"></Logo>
        <Logo src="/images/logos/logos-12.png"></Logo>
        <Logo src="/images/logos/logos-13.png"></Logo>
      </Logos>
      </ThirdLogoGroup>
      </LogosAndText>

    </FooterLogos>
    </FooterContainer>
  );
}
export default Footer;