import { styled } from 'styled-components';

const HomeGalleryContainer = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  height: ${props => !props.isMobile ? '140vh' : 'auto'};
  margin-top: 50px;
  overflow-x: hidden;

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

const CustomImage = styled.img`
  width: ${props => props.width};
  height: auto;
  top: ${props => props.top};
  right: ${props => props.right};
  left: ${props => props.left};
  z-index: 1;
  position: absolute;
  transition: transform 0.1s ease-in-out;
  
  &:hover {
    z-index: 2;
    transform: scale(1.3); /* Increase size by 10% */
  }
`;

const MobileImage = styled.img`
  width: 100vw;
  height: auto;
`;

const HomeGallery = () => {
  const isMobile = window.innerWidth < 768;

  return (
  <HomeGalleryContainer isMobile={isMobile}>
    {isMobile ? <MobileImage src={'/images/home-images/mobile_images.webp'} alt="home-gallery-01"/> : <>
    <CustomImage src={'/images/home-images/img_collage_postlike-01.webp'} alt="home-gallery-01" width={'20vw'} top={'10vh'} left={0}/>
    <CustomImage src={'images/home-images/img_collage_postlike-02.webp'} alt="home-gallery-02" width={'18vw'} top={'6vh'} left={'20vw'}/>
    <CustomImage src={'images/home-images/img_collage_postlike-09.webp'} alt="home-gallery-09" width={'21vw'} top={'22vh'} left={'23vw'}/>
    <CustomImage src={'images/home-images/img_collage_postlike-03.webp'} alt="home-gallery-03" width={'13vw'} top={'14vh'} left={'38vw'}/>
    <CustomImage src={'images/home-images/img_collage_postlike-04.webp'} alt="home-gallery-04" width={'14vw'} top={'19vh'} left={'17vw'}/>
    <CustomImage src={'images/home-images/img_collage_postlike-07.webp'} alt="home-gallery-07" width={'13vw'} top={'12vh'} right={'-1vw'}/>
    <CustomImage src={'images/home-images/img_collage_postlike-06.webp'} alt="home-gallery-06" width={'19vw'} top={'10vh'} right={'14vw'}/>
    <CustomImage src={'images/home-images/img_collage_postlike-13.webp'} alt="home-gallery-13" width={'21vw'} top={'38vh'} left={'40vw'}/>
    <CustomImage src={'images/home-images/img_collage_postlike-05.webp'} alt="home-gallery-05" width={'19vw'} top={'8vh'} right={'28vw'}/>
    <CustomImage src={'images/home-images/img_collage_postlike-11.webp'} alt="home-gallery-11" width={'20vw'} top={'40vh'} left={0}/>
    <CustomImage src={'images/home-images/img_collage_postlike-10.webp'} alt="home-gallery-10" width={'13vw'} top={'38vh'} left={'1vw'}/>
    <CustomImage src={'images/home-images/img_collage_postlike-14.webp'} alt="home-gallery-14" width={'18vw'} top={'49vh'} left={'35.5vw'}/>
    <CustomImage src={'images/home-images/img_collage_postlike-16.webp'} alt="home-gallery-16" width={'16vw'} top={'63vh'} left={'8vw'} />
    <CustomImage src={'images/home-images/img_collage_postlike-15.webp'} alt="home-gallery-15" width={'15vw'} top={'39vh'} right={'29vw'}/>
    <CustomImage src={'images/home-images/img_collage_postlike-08.webp'} alt="home-gallery-08" width={'15vw'} top={'24vh'} right={'39vw'}/>
    <CustomImage src={'images/home-images/img_collage_postlike-21.webp'} alt="home-gallery-21" width={'15vw'} top={'60vh'} left={'23vw'}/>
    <CustomImage src={'images/home-images/img_collage_postlike-17.webp'} alt="home-gallery-17" width={'14vw'} top={'38vh'} left={'64vw'}/>
    <CustomImage src={'images/home-images/img_collage_postlike-19.webp'} alt="home-gallery-19" width={'20vw'} top={'50vh'} right={'1vw'}/>
    <CustomImage src={'images/home-images/img_collage_postlike-18.webp'} alt="home-gallery-18" width={'10vw'} top={'62vh'} left={'73vw'}/>
    <CustomImage src={'images/home-images/img_collage_postlike-24.webp'} alt="home-gallery-24" width={'20vw'} top={'74vh'} left={'23vw'}/>
    <CustomImage src={'images/home-images/img_collage_postlike-20.webp'} alt="home-gallery-20" width={'15vw'} top={'64vh'} left={'25.8vw'}/>
    <CustomImage src={'images/home-images/img_collage_postlike-12.webp'} alt="home-gallery-12" width={'12vw'} top={'42vh'} left={'21vw'}/>
    <CustomImage src={'images/home-images/img_collage_postlike-23.webp'} alt="home-gallery-23" width={'18vw'} top={'99vh'} left={'7.2vw'}/>
    <CustomImage src={'images/home-images/img_collage_postlike-22.webp'} alt="home-gallery-22" width={'17vw'} top={'91vh'} left={'-5vw'}/>
    <CustomImage src={'images/home-images/img_collage_postlike-25.webp'} alt="home-gallery-25" width={'12vw'} top={'84.5vh'} left={'45vw'}/>
    <CustomImage src={'images/home-images/img_collage_postlike-27.webp'} alt="home-gallery-27" width={'20vw'} top={'87.5vh'} left={'59vw'}/>
    <CustomImage src={'images/home-images/img_collage_postlike-28.webp'} alt="home-gallery-28" width={'28vw'}  top={'91vh'} right={'-3vw'}/> 
    <CustomImage src={'images/home-images/img_collage_postlike-26.webp'} alt="home-gallery-26" width={'13vw'} top={'79.5vh'} left={'65vw'}/> 
    </>
    }
  </HomeGalleryContainer>);
}

export default HomeGallery