import Footer from './components/Footer/Footer.js';
import NavBar from './components/NavBar/NavBar.js';
import HomePage from './pages/HomePage/HomePage.js';
import ManifestoPage from './pages/ManifestoPage/ManifestoPage.js';
import TeamPage from './pages/TeamPage/TeamPage.js';

const { Navigate, Routes, Route } = require('react-router-dom');
const { styled } = require('styled-components');

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100vw;
  // hide scrollbar

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  // hide scrollbar end
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #000;
  margin: 20px;
  p {
    margin: 0;
  }

  @media (max-width: 768px) {
    margin: 0 0 20px 0;
  }
`;

const Caption = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;

  @media (max-width: 768px) {
    gap: 5px;
    margin-top: 20px;
  }
`;

const CaptionLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
`;

const TitleLine = styled(CaptionLine)`
  justify-content: space-evenly;
  margin-top: 40px;
`;

function App() {

  const isMobile = window.innerWidth < 768;

  return (
    <AppContainer>
      <HeaderContainer>
      <Caption>
        {!isMobile ? 
        <CaptionLine>
          <p>1ST</p>
          <p>YOUNG</p>
          <p>ARCHITECTURE</p>
          <p>BIENNIAL</p>
          <p>OF</p>
          <p>CATALONIA</p>
        </CaptionLine> 
        : <>
        <CaptionLine>
          <p>1ST</p>
          <p>YOUNG</p>
          <p>ARCHITECTURE</p>
        </CaptionLine>
        <CaptionLine>
          <p>BIENNIAL</p>
          <p>OF</p>
          <p>CATALONIA</p>
        </CaptionLine>
        <CaptionLine></CaptionLine>
        <CaptionLine></CaptionLine>
        <CaptionLine></CaptionLine>
        <CaptionLine></CaptionLine>
        </>
        }
        {!isMobile ? 
        <CaptionLine>
          <p>5</p>
          <p>–</p>
          <p>16</p>
          <p>OCTOBER</p>
          <p>2023</p>
          <p>/</p>
          <p>COL·LEGI</p>
          <p>D’ARQUITECTES</p>
          <p>DE</p>
          <p>CATALUNYA</p>
          <p>(COAC)</p>
        </CaptionLine>
        : <>
        <CaptionLine>
          <p>5</p>
          <p>–</p>
          <p>16</p>
          <p>OCTOBER</p>
          <p>2023</p>
        </CaptionLine>
        <CaptionLine>
          <p>COL·LEGI</p>
          <p>D’ARQUITECTES</p>
          <p>DE</p>
          <p>CATALUNYA</p>
          <p>(COAC)</p>
        </CaptionLine>
        </> }
      </Caption>
      </HeaderContainer>
      <NavBar/>
      {!isMobile &&
      <Caption>
      <TitleLine>
        <p>POST</p>
        <p>LIKE</p>
      </TitleLine>
      </Caption>
      }
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<HomePage/>} exact />
        <Route path="/manifesto" element={<ManifestoPage/>} />
        <Route path="/team" element={<TeamPage/>} />
      </Routes>
      <Footer/>
    </AppContainer>
  );
}
export default App;